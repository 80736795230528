import $ from 'jquery';
import { escapeHtml } from './utils';

/**
 * Makes FAQ selection dynamic.
 * @returns {void} no return
 */
export default function faqMakeDynamic() {
  const hasFaq = document.querySelector('.faq_content');
  if (!hasFaq) return;

  $('.panel-group').each(function eachElement() {
    const id = $(this).attr('id');
    const title = $(this).data('title');
    let option;
    if (title) {
      option = $('<option></option>')
        .val(id)
        // we need to do proper HTML escaping for the title otherwise even escaped output from php can reappear as html...
        .text(escapeHtml(title));
      // another way to do HTML escaping is the combination of text().html() as in this example
      // option = $('<option></option>').val(id).text(title).html();
      $('.faq_content .select').append(option);
    }
  });

  if ($.isFunction($.fn.fancySelect)) {
    $('.select')
      .fancySelect()
      .on('change.fs', () => {
        $('.panel-group .panel-group').hide();
        $(`#${this.value}`).show();
      });
    $('.panel-group .panel-group').hide();
    $(
      `#${
        $('.select option')
          .first()
          .val()}`,
    ).show();
  }
}
