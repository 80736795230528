import $ from 'jquery';

/**
 * Author: Heather Corey
 * jQuery Simple Parallax Plugin
 *
 * @param {object} options settings object
 * @returns {void} no return
 */
$.fn.balacparallax = function baslacparallax(options) {
  const windowHeight = $(window).height();

  // Establish default settings
  const settings = $.extend(
    {
      speed: 0.15,
    },
    options,
  );

  // Iterate over each object in collection
  return this.each(function eachElement() {
    // Save a reference to the element
    const $this = $(this);

    // Set up Scroll Handler
    $(document).scroll(() => {
      const scrollTop = $(window).scrollTop();
      const offset = $this.offset().top;
      const height = $this.outerHeight();

      // Check if above or below viewport
      if (offset + height <= scrollTop || offset >= scrollTop + windowHeight) {
        return;
      }

      let yBgPosition = Math.round((offset - scrollTop) * settings.speed);

      if (yBgPosition < 0) {
        yBgPosition = 0;
      }
      // Apply the Y Background Position to Set the Parallax Effect
      $this.css('background-position', `center ${yBgPosition}px`);
    });
  });
};
