import $ from 'jquery';

/**
 * Activates the slider for testimonials.
 * @returns {void} no return
 */
export default function testimonialsSliderInit() {
  const hasTestimonials = document.querySelector('.flexslider');
  if (!hasTestimonials) return;

  $('.flexslider').flexslider({
    animation: 'slide',
  });
}
