import $ from 'jquery';
import device from 'current-device';
import PerfectScrollbar from 'perfect-scrollbar';

import { aPosition } from './utils';

const WINDOW_WIDTH = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
const WINDOW_HEIGHT = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

let ps = null;

const detectOrientation = () => {
  const sideBarNav = document.querySelector('#sidebar nav');

  if (WINDOW_WIDTH > WINDOW_HEIGHT) {
    sideBarNav.classList.add('landscape');
    sideBarNav.classList.remove('portret');
  }
  else if (WINDOW_HEIGHT > WINDOW_WIDTH) {
    sideBarNav.classList.add('portret');
    sideBarNav.classList.remove('landscape');
  }
};

/**
 * Custom Scrollbar on Navigation.
 * @returns {void} no return
 */
function mainMenuInitScrollbar() {
  const scrollOptions = {
    suppressScrollX: true,
    minScrollbarLength: 100,
  };

  if (device.desktop() || (device.tablet() && device.landscape())) {
    ps = new PerfectScrollbar('#sidebar nav > ul', scrollOptions);
  }
}

/**
 * Adapted from getPageSize() by quirksmode.com.
 * @returns {Int} pixels of pageheight
 */
function getPageHeight() {
  let windowHeight;
  if (window.self.innerHeight) {
    // All except Explorer.
    windowHeight = window.self.innerHeight;
  }
  else if (document.documentElement && document.documentElement.clientHeight) {
    windowHeight = document.documentElement.clientHeight;
  }
  else if (document.body) {
    // Other Explorers.
    windowHeight = document.body.clientHeight;
  }
  return windowHeight;
}

const updateMenuScrollAndSpecialLinksPosition = (() => {
  //        return function() {} ;
  const $navContainer = $('nav#nav');
  // we consider only two cases that trigger the menu update:
  // a change in the page window height...
  const lastViewPortHeight = getPageHeight();
  // or a change of the menu content height
  const lastNavContainerHeight = $navContainer.height();

  $('#sidebar nav').css('padding', 0);
  $('a.close-menu').css('margin-bottom', 0);

  // if mobile or table portrait : adapts menu link height according to available space

  // temporary DISABLED
  const enabled = true;
  if ((device.mobile() && enabled) || (device.tablet() && device.portrait())) {
    const $menuLinks = $navContainer.find('ul.nav > li');
    $menuLinks
      .find('a, span')
      .css('padding', 0)
      .css('background-image', 'none')
      .css('padding-left', 40);
    const minHeight = 30;
    const maxHeight = 90;
    const maxFontSize = 40;
    const menuLinkCount = $menuLinks.size();
    return () => {
      const viewPortHeight = getPageHeight();
      $navContainer.height('auto');
      $menuLinks.height(maxHeight);
      $navContainer.find('li.special:first').css('marginTop', 0);

      const navContainerTop = aPosition($navContainer).top;
      const newNavContainerHeight = viewPortHeight - navContainerTop;
      let newMenuLinkHeight = parseInt(newNavContainerHeight / menuLinkCount, 10);

      if (newMenuLinkHeight < minHeight) {
        newMenuLinkHeight = minHeight;
      } else if (newMenuLinkHeight > maxHeight) {
        newMenuLinkHeight = maxHeight;
      }

      const lastMenuLinkHeight = newMenuLinkHeight + parseInt(newNavContainerHeight % menuLinkCount, 10);

      $menuLinks.height(newMenuLinkHeight);
      $menuLinks
        .find('ul')
        .parent()
        .height('auto');
      $menuLinks.css('line-height', `${newMenuLinkHeight.toString()}px`);
      $menuLinks
        .last()
        .height(lastMenuLinkHeight)
        .css('line-height', `${lastMenuLinkHeight.toString()}px`);
      let newFontSize = newMenuLinkHeight * 0.5;

      if (newFontSize > maxFontSize) {
        newFontSize = maxFontSize;
      }
      $menuLinks.find('a, span').css('font-size', newFontSize);
      const menuTotalHeight = (menuLinkCount - 1) * newMenuLinkHeight + lastMenuLinkHeight;
      if (newNavContainerHeight > menuTotalHeight) {
        $navContainer
          .find('li.special:first')
          .css('marginTop', newNavContainerHeight - menuTotalHeight);
      }
    };
    // else if desktop or tablet landscape
  }
  return () => {
    const viewPortHeight = getPageHeight();
    // We reset some styles in order to get the real height of the navigation container.
    $navContainer.height('auto');
    $navContainer.find('li.special:first').css('marginTop', 0);
    const navContainerHeight = $navContainer.height();

    if (viewPortHeight !== lastViewPortHeight || navContainerHeight !== lastNavContainerHeight) {
      const navContainerTop = aPosition($navContainer).top;
      const menuDoesNotFit = navContainerTop + navContainerHeight > viewPortHeight;

      if (menuDoesNotFit) {
        $navContainer.height(viewPortHeight - navContainerTop);
        if (!ps) {
          mainMenuInitScrollbar();
        }
      }
      else {
        $navContainer.height('auto');
        $navContainer
          .find('li.special:first')
          .css('marginTop', viewPortHeight - (navContainerTop + navContainerHeight));
        if (ps) {
          ps.destroy();
        }
      }
    }
  };
})();


/**
 * Does some magic main menu height setting.
 * @returns {void} no return
 */
export function mainMenuManageHeight() {
  if (document.querySelector('.nav.nav-pills')) {
    const navPills = document.querySelector('.nav.nav-pills');

    navPills.addEventListener('show.bs.dropdown', (e) => {
      if (e.relatedTarget.href !== '#') {
        updateMenuScrollAndSpecialLinksPosition();
      }
    });

    navPills.addEventListener('hide.bs.dropdown', () => {
      const expandedElements = navPills.querySelectorAll('.expanded.open');
      expandedElements.forEach((elem) => {
        if (!elem.classList.contains('active-trail')) {
          elem.classList.remove('open');
        }
      });
    });
  }
}

/**
 * Adds a class for the orientation if the device is small.
 * @returns {void} no return
 */
export function mainMenuSmallDeviceOrientation() {
  document.addEventListener('resize', detectOrientation);

  if (WINDOW_WIDTH < 768) {
    detectOrientation();
  }
}

/**
 * Adds inpage navigation links to the main menu.
 * @returns {void} no return
 */
export function mainMenuAddInpageLinks() {
  const submenu = document.createElement('ul');
  submenu.role = 'menu';
  submenu.classList.add('dropdown-menu');

  const sections = document.querySelectorAll('.region-content section');
  sections.forEach((section) => {
    const menuName = section.dataset.menuname;
    const menuItem = document.createElement('li');
    const menuLink = document.createElement('a');
    menuLink.href = `#${section.id}`;
    menuLink.textContent = menuName;
    menuItem.addEventListener('click', (e) => {
      e.preventDefault();
      $('html, body').animate(
        {
          scrollTop: $($(e.target).attr('href')).offset().top,
        },
        1250,
      );
    });

    if (section.id) {
      menuItem.appendChild(menuLink);
      submenu.appendChild(menuItem);
    }
  });

  const submenuItems = submenu.querySelectorAll('li');
  if (submenuItems.length > 0) {
    $('nav#nav li.active')
      .addClass('dropdown open')
      .attr('role', 'presentation');
    $('nav#nav li.active a.active')
      .addClass('dropdown-toggle')
      .attr('data-toggle', 'dropdown')
      .attr('aria-expanded', 'true');
    $('nav#nav li.active').append($(submenu));
  }

  setTimeout(() => {
    $('.region-content section[data-menuname]').waypoint({
      handler(direction) {
        if (direction === 'down') {
          $('.dropdown-menu li a').removeClass('mhover');
          $(`.dropdown-menu li a:contains("${$(this).data('menuname')}")`).addClass(
            'mhover',
          );
        }
      },
      offset: 200,
    });
    $('.region-content section[data-menuname]').waypoint({
      handler(direction) {
        if (direction === 'up') {
          $('.dropdown-menu li a').removeClass('mhover');
          $(`.dropdown-menu li a:contains("${$(this).data('menuname')}")`)
            .closest('li')
            .prev()
            .find('a')
            .addClass('mhover');
        }
      },
      offset: 200,
    });
  }, 100);
}

/**
 *
 * @returns {void} no return
 */
export function menuInitMobile() {
  $('.menu-mobile').click(() => {
    updateMenuScrollAndSpecialLinksPosition();

    $('#sidebar').fadeTo(0, 1);
    $('.region').addClass('click_disabled');
    $('body').addClass('mobile-nav-open');
    $('#sidebar').animate({ left: '0%' }, () => {});
    $('.overlay')
      .fadeIn()
      .click(() => {
        $('.close-menu').trigger('click');
      });
    return false;
  });

  $('.close-menu').click(() => {
    updateMenuScrollAndSpecialLinksPosition();

    $('#sidebar').animate({ left: '-700px' });
    $('.region').removeClass('click_disabled');
    $('body').removeClass('mobile-nav-open');
    $('.overlay').fadeOut();

    return false;
  });
}

/**
 * Handles the special links.
 * @returns {void} no return
 */
export function mainMenuInitSpecialLinks() {
  $('nav#nav li a.special').each(function eachElement() {
    const $specialLink = $(this);
    const classList = $specialLink.attr('class').split(/\s+/);

    $.each(classList, (index, item) => {
      $specialLink.parent().addClass(item);
    });
  });

  $('nav#nav li.special').each(function eachElement() {
    $('nav#nav > ul').append($(this).detach());
  });

  $(window).resize(updateMenuScrollAndSpecialLinksPosition);
  setTimeout(updateMenuScrollAndSpecialLinksPosition, 10);
  setTimeout(updateMenuScrollAndSpecialLinksPosition, 100);
  setTimeout(updateMenuScrollAndSpecialLinksPosition, 200);
  setTimeout(updateMenuScrollAndSpecialLinksPosition, 400);
  setTimeout(updateMenuScrollAndSpecialLinksPosition, 800);
  // setTimeout(updateMenuScrollAndSpecialLinksPosition, 1600);
}
