import $ from 'jquery';
import debounce from 'lodash.debounce';

import '../utils/parallax';

/**
 * Initializes the scroll down button in the header splash image.
 *
 * @param {String} buttonSelector Scroll down button element.
 * @returns {void} no return
 */
export function headerScrollDownButton(buttonSelector) {
  const button = document.querySelector(buttonSelector);
  if (!button) return;
  button.addEventListener('click', () => {
    // TODO: why is both html and body animated here?
    $('html, body').animate(
      {
        scrollTop: $('.region').offset().top + 10,
      },
      1250,
    );
    return false;
  });
}

/**
 * Initializes the height of the header element to the height of the header div and
 * updates the header element accordingly on window resize.
 *
 * @param {String} headerSelector #header div element.
 * @returns {void} no return
 */
export function headerSyncHeights(headerSelector) {
  const headerDiv = document.querySelector(headerSelector);
  if (!headerDiv) return;

  const syncHeaderHeight = debounce(() => {
    $('header').height($(headerDiv).height());
  }, 10);
  syncHeaderHeight();

  $(window).resize(syncHeaderHeight);
}

/**
 * Initializes the Headerparalax
 *
 * preloads the image first
 * using the paralax api resize event to update after creation // IE bug
 *
 * @returns {void} no return
 */
export function headerParalaxInit() {
  const hasHeaderParallax = document.querySelector('#header,.paralax-header');
  if (!hasHeaderParallax) return;

  $('.paralax-header section').balacparallax({
    speed: -0.65,
  });
}
