import $ from 'jquery';

/**
 * Creates the parallax effect for section background images.
 *
 * @param {String} sectionBackgroundsSelector background elements selector
 * @returns {void} no return
 */
export default function sectionBackgroundImageParallax(sectionBackgroundsSelector) {
  const sectionBackgrounds = document.querySelectorAll(sectionBackgroundsSelector);
  if (!sectionBackgrounds) return;

  sectionBackgrounds.forEach((bgobj) => {
    const $bgobj = $(bgobj);
    $(window).scroll(() => {
      const yPos = -(($(window).scrollTop() - $bgobj.position().top) / $bgobj.data('speed'));
      const coords = `50% ${yPos}px`;
      $bgobj.css({ backgroundPosition: coords });
    });
  });
}
