import $ from 'jquery';
import device from 'current-device';

/**
 * Viewport limits
 */
const VIEWPORT_MOBILE = 720;
const VIEWPORT_TABLET = 1200;

/**
 * Entity map
 */
const ENTITY_MAP = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;',
  '/': '&#x2F;',
};

/**
 * Escape html string entities
 * @param {String} input string to be escaped
 * @returns {String} output
 */
export function escapeHtml(input) {
  return String(input).replace(/[&<>"'/]/g, (s) => ENTITY_MAP[s]);
}

/**
 * Wraps registration trademark characters in the content of the page.
 * @returns {void} no return
 */
export function superscriptRegistrationTrademark() {
  $('p,h1,h2,h3,h4,a,span').each(function eachElement() {
    $(this).html(
      $(this)
        .html()
        .replace(/&reg;/gi, '<sup>&reg;</sup>')
        .replace(/®/gi, '<sup>&reg;</sup>'),
    );
  });
}


/**
 * Detects and sets the viewport settings.
 * @returns {void} no return
 */
export function detectViewportSettings() {
  const updateViewportSettings = () => {
    if (device.mobile() || device.tablet()) {
      const viewportWidth = device.tablet() === true && device.landscape()
        ? VIEWPORT_TABLET
        : VIEWPORT_MOBILE;

      const vpTag = document.querySelector('meta[name="viewport"]');
      if (vpTag) {
        vpTag.content = `width=${viewportWidth}`;
      }

      let vpStyle = document.querySelector('#dyn-viewport');
      if (!vpStyle) {
        vpStyle = document.createElement('style');
        vpStyle.id = 'dyn-viewport';
        document.head.appendChild(vpStyle);
      }

      const viewportStyle = `
        @-webkit-viewport{width: ${viewportWidth}px !important}
        @-moz-viewport{width: ${viewportWidth}px !important}
        @-ms-viewport{width: ${viewportWidth}px !important}
        @-o-viewport{width: ${viewportWidth}px !important}
        @viewport{width: ${viewportWidth}px !important}
      `;

      vpStyle.innerHTML = viewportStyle;
    }
  };

  document.addEventListener('orientationchange', () => {
    document.location.reload();
  });

  updateViewportSettings();
}

/**
 * Get offset top in chrome
 * @param {JQuery} $element jQuery Element
 * @returns {Object} position information
 */
export function aPosition($element) {
  const { left: elementLeft, top: elementTop } = $element.offset();
  const elementParent = $element.parent();
  const { left: parentLeft, top: parentTop } = elementParent.offset();

  return {
    left: elementLeft - parentLeft,
    top: elementTop - parentTop,
  };
}

/**
 * Makes the page content visible.
 * @returns {void} no return
 */
export function bodyUnhide() {
  document.querySelector('body').classList.remove('hidden');
}
