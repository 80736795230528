import $ from 'jquery';
import debounce from 'lodash.debounce';

import { readCookie, createCookie } from '../utils/cookie';

/**
 * @returns {void} no return
 */
export function toolInit() {
  const hasTool = document.querySelector('#tool .tool_content');
  if (!hasTool) return;

  const fromcook = readCookie('tool_start');
  $('#tool .tool_content').prepend(fromcook);
  const mlid = readCookie('Drupal.visitor.mlid');
  $(`.menu-${mlid}`)
    .addClass('active-trail last active')
    .find('a')
    .addClass('active-trail active');
  $(document).on('click', '[data-toggle="lightbox"]', function eachElement(event) {
    event.preventDefault();
    $(this).ekkoLightbox({
      alwaysShowClose: true,
    });
  });

  const adaptHeights = debounce(() => {
    const groups = {};
    $('.tool_content_box').each(function eachElement() {
      const $productDetail = $(this);
      const { top } = $productDetail.offset();
      if (Object.hasOwnProperty.call(groups, 'top')) {
        groups[top].push(this);
      } else {
        groups[top] = [this];
      }
    });

    Object.keys(groups).forEach((groupTop) => {
      $(groups[groupTop]).equalHeights();
    });
  }, 100);

  $(window).resize(adaptHeights);
  adaptHeights();

  $.each($('.usecount .tool_content_box'), (index, item) => {
    $(item).append(`<div class='count'>${index + 1}</div>`);
  });
  setTimeout(() => {
    adaptHeights();
  }, 100);
  setTimeout(() => {
    adaptHeights();
  }, 300);
  setTimeout(() => {
    adaptHeights();
  }, 500);
}

/**
 * @returns {void} no return
 */
export function toolPageInit() {
  const hasTool = document.querySelector('#tool_start_point');
  if (!hasTool) return;

  let prevValues = $('#tool_start_point').prevAll();
  prevValues = $('<div />')
    .append(
      prevValues
        .clone()
        .get()
        .reverse(),
    )
    .html();
  createCookie('tool_start', prevValues);
}
