import 'bootstrap';
import 'ekko-lightbox';
import 'flexslider';
import 'jquery-parallax.js/parallax';
import 'lockfixed/jquery.lockfixed';
import 'jquery-waypoints/waypoints';
import 'jquery-validation';

// vendors
import './vendor/fancySelect';
import './vendor/equalHeights';
import './vendor/changeHeight';
import './vendor/modernizr';

// components
import productsAdaptHeight from './components/productsAdaptHeight';
import {
  mainMenuSmallDeviceOrientation,
  mainMenuManageHeight,
  mainMenuAddInpageLinks,
  menuInitMobile,
  mainMenuInitSpecialLinks,
} from './components/mainmenu';
import initAllpages from './components/pages';
import { superscriptRegistrationTrademark, detectViewportSettings, bodyUnhide } from './components/utils';
import { headerScrollDownButton, headerParalaxInit, headerSyncHeights } from './components/header';
import contactFormMakeFancy from './components/form';
import sectionBackgroundImageParallax from './components/parallax';
import { toolInit, toolPageInit } from './components/tool';
import testimonialsSliderInit from './components/testimonials';
import homepageLanguageSelector from './components/languageselector';
import faqMakeDynamic from './components/faq';
import videoInit from './components/video';

// this fixes a problem with fancyselect in firefox
const nAgt = navigator.userAgent;
if ((nAgt.indexOf('Firefox')) >= 0) {
  document.body.classList.add('browser-mozilla');
}

bodyUnhide();
detectViewportSettings();
productsAdaptHeight();

mainMenuSmallDeviceOrientation();
mainMenuManageHeight();
mainMenuAddInpageLinks();
menuInitMobile();
mainMenuInitSpecialLinks();

initAllpages();
superscriptRegistrationTrademark();

headerScrollDownButton('header .arrow-down');
headerSyncHeights('#header');

contactFormMakeFancy('.contact-form');
sectionBackgroundImageParallax('.content-bgi-paralax');
faqMakeDynamic();
videoInit();

toolInit();
toolPageInit();
testimonialsSliderInit();
headerParalaxInit();

homepageLanguageSelector();
