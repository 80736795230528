import $ from 'jquery';
import 'jquery-tubeplayer-plugin/dist/jquery.tubeplayer';

/**
 * YT video player interaction
 *
 * TODO: Make sure the code runs for two containers as well..
 *
 * @returns {void} no return
 */
export default function videoInit() {
  const hasVideo = document.querySelector('.video-group');
  if (!hasVideo) return;

  $('.youtube').each(function eachElement() {
    // find preview image based on youtube id...
    if (!$(this).data('preview')) {
      $(this).css(
        'background-image',
        `url(https://i.ytimg.com/vi/${$(this).data('url')}/sddefault.jpg)`,
      );
    } else {
      $(this).css('background-image', `url("${$(this).data('preview')}")`);
    }

    // overlay the Play icon to make it look like a video player
    $(this).append($('<div/>', { class: 'play' }));

    $('.vplayer .vdescription').prependTo('.video-group');

    $(this).click(function eachClick() {
      const ytUrlRaw = $(this).data('url');

      const $video = $(
        '<div id="video"  class="video-js vjs-default-skin" controls preload="auto" width="400" height="315"></video>',
      );

      // replace the YouTube thumbnail with YouTube HTML5 Player
      $('.vplayer').html($video);

      $('#video').tubeplayer({
        width: 400, // the width of the player
        height: 315, // the height of the player
        allowFullScreen: 'true', // true by default, allow user to go full screen
        initialVideo: ytUrlRaw, // the video that is loaded into the player
        preferredQuality: 'medium', // preferred quality: default, small, medium, large, hd720
        autoPlay: true,
      });
      $('.video-group .vdescription')
        .first()
        .text($(this).data('description'));
      $('html, body').animate(
        {
          scrollTop: $('#video').offset().top + 100,
        },
        750,
      );
    });
  });
}
