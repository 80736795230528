/**
 * create a cookie
 *
 * @param {String} name name of cookie
 * @param {String} value value of cookie
 * @param {Int} days days to set
 * @returns {void} no return
 */
export function createCookie(name, value, days) {
  let expires;

  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toGMTString()}`;
  } else {
    expires = '';
  }
  document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}${expires}; path=/`;
}

/**
 * Read a cookie
 *
 * @param {String} name name of cookie
 * @returns {String} cookie value
 */
export function readCookie(name) {
  const nameEQ = `${encodeURIComponent(name)}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
  }
  return null;
}

/**
 * Erase a cookie
 *
 * @param {String} name name of cookie
 * @returns {void} no return
 */
export function eraseCookie(name) {
  createCookie(name, '', -1);
}
