/**
 * Make the height of products equal within each group.
 */
import $ from 'jquery';
import debounce from 'lodash.debounce';

/**
 * @returns {void} no return
 */
export default function productsAdaptHeight() {
  const productPresent = document.querySelector('.product_details');

  if (!productPresent) {
    return;
  }

  const adaptHeights = debounce(() => {
    const groups = {};
    $('.product_details').each(function eachElement() {
      const $productDetail = $(this);
      const { top } = $productDetail.offset();
      if (Object.hasOwnProperty.call(groups, 'top')) {
        groups[top].push(this);
      } else {
        groups[top] = [this];
      }
    });

    Object.keys(groups).forEach((groupTop) => {
      $(groups[groupTop]).equalHeights();
    });
  }, 10);

  document.addEventListener('resize', adaptHeights);

  adaptHeights();

  // it seemingly takes some time for the render engine to
  // know about the real product positions, that is
  // the reason why we try again and again
  setTimeout(adaptHeights, 10);
  setTimeout(adaptHeights, 100);
  setTimeout(adaptHeights, 200);
  setTimeout(adaptHeights, 400);
  setTimeout(adaptHeights, 800);
  setTimeout(adaptHeights, 1600);
}
