import $ from 'jquery';

/**
 * Creates a fancy select box for the contact form.
 *
 * @param {String} contactFormSelector selector to node that contains the contact form.
 * @returns {void} no return
 */
export default function contactFormMakeFancy(contactFormSelector) {
  const contactForm = document.querySelector(contactFormSelector);
  if (!contactForm) return;

  if ($.isFunction($.fn.fancySelect)) {
    $(contactForm)
      .find('select')
      .fancySelect();
  }
}
