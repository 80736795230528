import $ from 'jquery';
import { eraseCookie } from '../utils/cookie';

const { Drupal } = window;

/**
 * @returns {void} no return
 */
export default function initAllpages() {
  $('.clear_cookie').click(() => {
    const cookieSelection = 'baslac-nid';
    const cookieLang = 'baslac-language';

    eraseCookie(cookieSelection);
    eraseCookie(cookieLang);
  });

  $('.special a').attr('target', '_blank');

  $('.panel-heading a').focus(function eachElement() {
    $(this).blur();
  });

  $('.panel').on('show.bs.collapse', (e) => {
    $(e.currentTarget).addClass('active');
  });

  $('.panel').on('hide.bs.collapse', (e) => {
    $(e.currentTarget).removeClass('active');
  });

  $('#nav').on({
    'hide.bs.dropdown': () => false,
  });

  // header position fixed
  // TODO: check if still needed
  if ($(window).width() < 768) {
    $(window).scroll(() => {
      if ($(this).scrollTop() > 100) {
        $('.nav-mobile').addClass('fixed-position');
      } else {
        $('.nav-mobile').removeClass('fixed-position');
      }
    });
  }

  // TODO: check if still needed
  $('.in')
    .prev()
    .addClass('opened');
  $('.panel-title a').click(function eachElement() {
    $('.panel-heading').removeClass('opened');
    $(this)
      .closest('.panel-heading')
      .addClass('opened');
    if (
      $(this)
        .parent()
        .parent()
        .parent()
        .find('.panel-collapse')
        .hasClass('in')
    ) {
      $('.panel-heading').removeClass('opened');
    }
  });

  // TODO: check if still needed
  // overlay menu mobile
  $('.overlay').css({ height: $(document).height() });

  // highlight
  // TODO: check if still needed
  const elements = $("input[type!='submit'], textarea, select");
  elements.focus(function eachElement() {
    $(this)
      .parents('li')
      .removeClass('highlight');
  });
  elements.blur(function eachElement() {
    $(this)
      .parents('li')
      .removeClass('highlight');
  });

  $('#forgotpassword').click(() => {
    $('#password').removeClass('required');
    $('#login').submit();
    $('#password').addClass('required');
    return false;
  });

  $('#login').validate();
}
