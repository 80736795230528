/**
 * change height listener
 */
(function(e) {
  function t() {
    var e = document.createElement('p');
    var t = false;
    if (e.addEventListener)
      e.addEventListener(
        'DOMAttrModified',
        function() {
          t = true;
        },
        false
      );
    else if (e.attachEvent)
      e.attachEvent('onDOMAttrModified', function() {
        t = true;
      });
    else return false;
    e.setAttribute('id', 'target');
    return t;
  }

  function n(t, n) {
    if (t) {
      var r = this.data('attr-old-value');
      if (n.attributeName.indexOf('style') >= 0) {
        if (!r['style']) r['style'] = {};
        var i = n.attributeName.split('.');
        n.attributeName = i[0];
        n.oldValue = r['style'][i[1]];
        n.newValue = i[1] + ':' + this.prop('style')[e.camelCase(i[1])];
        r['style'][i[1]] = n.newValue;
      } else {
        n.oldValue = r[n.attributeName];
        n.newValue = this.attr(n.attributeName);
        r[n.attributeName] = n.newValue;
      }
      this.data('attr-old-value', r);
    }
  }

  var r = window.MutationObserver || window.WebKitMutationObserver;
  e.fn.attrchange = function(i) {
    var s = { trackValues: false, callback: e.noop };
    if (typeof i === 'function') {
      s.callback = i;
    } else {
      e.extend(s, i);
    }
    if (s.trackValues) {
      e(this).each(function(t, n) {
        var r = {};
        for (var i, t = 0, s = n.attributes, o = s.length; t < o; t++) {
          i = s.item(t);
          r[i.nodeName] = i.value;
        }
        e(this).data('attr-old-value', r);
      });
    }
    if (r) {
      var o = { subtree: false, attributes: true, attributeOldValue: s.trackValues };
      var u = new r(function(t) {
        t.forEach(function(t) {
          var n = t.target;
          if (s.trackValues) {
            t.newValue = e(n).attr(t.attributeName);
          }
          s.callback.call(n, t);
        });
      });
      return this.each(function() {
        u.observe(this, o);
      });
    } else if (t()) {
      return this.on('DOMAttrModified', function(e) {
        if (e.originalEvent) e = e.originalEvent;
        e.attributeName = e.attrName;
        e.oldValue = e.prevValue;
        s.callback.call(this, e);
      });
    } else if ('onpropertychange' in document.body) {
      return this.on('propertychange', function(t) {
        t.attributeName = window.event.propertyName;
        n.call(e(this), s.trackValues, t);
        s.callback.call(this, t);
      });
    }
    return this;
  };
})(jQuery);
