import $ from 'jquery';
import { readCookie, eraseCookie, createCookie } from '../utils/cookie';

const { Drupal } = window;

/**
 * Init the Homepage fancyselect
 *
 * redirect to site homepage if cookie is set
 * OR set cookie after user selection
 *
 * @returns {void} no return
 */
export default function homepageLanguageSelector() {
  const hasLanguageSelector = document.querySelector('.homepage');
  if (!hasLanguageSelector) return;

  const cookieSelection = 'baslac-nid';
  const cookieLang = 'baslac-language';
  const checkCookieLanguage = readCookie(cookieLang);
  const checkCookieNid = readCookie(cookieSelection);
  if (checkCookieLanguage === null || checkCookieNid === null) {
    $('.language_content .select-content .fancy-select .trigger').click(function clickElement() {
      const cookieData = $(this).data('value');
      const frontpageLang = $(this).data('lang');

      eraseCookie(cookieSelection);
      createCookie(cookieSelection, cookieData, 10000);

      eraseCookie(cookieLang);
      createCookie(cookieLang, frontpageLang, 10000);

      window.location.href = Drupal.settings.frontpages[cookieData];
      return false;
    });

    if ($.isFunction($.fn.fancySelect)) {
      $('.homepage select').fancySelect();

      $('.homepage select')
        .fancySelect()
        .on('change.fs', () => {
          const cookieData = this.value;
          const frontpageLang = $(`select option[value="${cookieData}"]`).data('lang');

          eraseCookie(cookieSelection);
          createCookie(cookieSelection, cookieData, 10000);

          eraseCookie(cookieLang);
          createCookie(cookieLang, frontpageLang, 10000);

          window.location.href = Drupal.settings.frontpages[cookieData];
        });
    }
  } else {
    window.location.href = Drupal.settings.frontpages[checkCookieNid];
  }
}
